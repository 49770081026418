import * as React from 'react';
import * as styles from './page-headline.module.scss';

interface IProps {
  children: React.ReactElement | string;
  className?: string;
}
export const PageHeadline = ({ children, className = '' }: IProps) => (
  <h1 className={`${styles.headline} ${className}`}>{children}</h1>
);
