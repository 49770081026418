import * as React from 'react';
import { PageProps } from 'gatsby';
import { MainTemplate } from '../../templates/main/main';
import { SEO } from '../../components/seo/seo';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { PageHeadline } from '../../components/page-headline/page-headline';
import { ProductsGrid } from '../../components/products-grid/products-grid';

const ProductsPage = ({ location }: PageProps) => {
  const intl = useIntl();

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({ id: 'products.seo_title' })}
        description={intl.formatMessage({ id: 'products.seo_description' })}
      />
      <div className="container">
        <PageHeadline>
          <FormattedMessage id="products.headline" />
        </PageHeadline>
        <ProductsGrid />
      </div>
    </MainTemplate>
  );
};

export default ProductsPage;
