import * as React from 'react';
import { ProductCard } from '../product-card/product-card';
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { ROUTES } from '../../const/routes';
import { Routes } from '../../enum/Routes';

export const ProductsGrid = () => {
  const graphqlData = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          absolutePath: { regex: "/products/" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: TRACED_SVG
                width: 320
              )
            }
          }
        }
      }
    }
  `);

  const images = useMemo(() => {
    return graphqlData.images.edges.reduce(
      (prev: any, { node: { name, childImageSharp } }: any) => ({
        ...prev,
        [name]: childImageSharp.gatsbyImageData,
      }),
      {}
    );
  }, [graphqlData]);

  const products = [
    {
      name: 'products.products_data.transport_boxes.name',
      description: 'products.products_data.transport_boxes.description',
      image: images['transport-box-1'],
      path: ROUTES[Routes.PRODUCTS_TRANSPORT_BOXES](),
    },
    {
      name: 'products.products_data.pizza_boxes.name',
      description: 'products.products_data.pizza_boxes.description',
      image: images['pizza-box-1'],
      path: ROUTES[Routes.PRODUCTS_PIZZA_BOXES](),
    },
    {
      name: 'products.products_data.food_boxes.name',
      description: 'products.products_data.food_boxes.description',
      image: images['food-box-1'],
      path: ROUTES[Routes.PRODUCTS_FOOD_BOXES](),
    },
    {
      name: 'products.products_data.luxury_laminated_boxes.name',
      description: 'products.products_data.luxury_laminated_boxes.description',
      image: images['luxury-laminated-box-1'],
      path: ROUTES[Routes.PRODUCTS_LUXURY_LAMINATED_BOXES](),
    },
    {
      name: 'products.products_data.punched_boxes.name',
      description: 'products.products_data.punched_boxes.description',
      image: images['punched-box-1'],
      path: ROUTES[Routes.PRODUCTS_PUNCHED_BOXES](),
    },
    {
      name: 'products.products_data.wine_boxes.name',
      description: 'products.products_data.wine_boxes.description',
      image: images['wine-box-1'],
      path: ROUTES[Routes.PRODUCTS_WINE_BOXES](),
    },
    {
      name: 'products.products_data.octabin_boxes.name',
      description: 'products.products_data.octabin_boxes.description',
      image: images['octabin-1'],
      path: ROUTES[Routes.PRODUCTS_OCTABIN_BOXES](),
    },
    {
      name: 'products.products_data.pallet_boxes.name',
      description: 'products.products_data.pallet_boxes.description',
      image: images['pallet-1'],
      path: ROUTES[Routes.PRODUCTS_PALLET_BOXES](),
    },
    {
      name: 'products.products_data.crate_boxes.name',
      description: 'products.products_data.crate_boxes.description',
      image: images['cardboard-box-no-top-1'],
      path: ROUTES[Routes.PRODUCTS_CRATE_BOXES](),
    },
    {
      name: 'products.products_data.grand_playhouse.name',
      description: 'products.products_data.grand_playhouse.description',
      image: images['grand-playhouse-1'],
      path: ROUTES[Routes.PRODUCTS_GRAND_PLAYHOUSE](),
    },
  ];
  return (
    <section className="row">
      {products.map(product => (
        <ProductCard
          key={product.name}
          className="d-flex col-12 col-md-6 col-lg-4"
          product={product}
        />
      ))}
    </section>
  );
};
