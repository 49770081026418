import * as React from 'react';
import * as styles from './product-card.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-react-intl';

interface IProps {
  className: string | undefined;
  product: {
    name: string;
    description: string;
    image: IGatsbyImageData;
    path: string;
  };
}
export const ProductCard = ({ className = '', product }: IProps) => {
  const intl = useIntl();

  return (
    <div className={className}>
      <Link to={product.path} className={`d-flex flex-column ${styles.card}`}>
        <div className="d-flex flex-column">
          <GatsbyImage
            image={product.image}
            loading="eager"
            alt={`${intl.formatMessage({
              id: product.name,
            })} - ${intl.formatMessage({ id: product.description })}`}
          />
          <h2 className={styles.headline}>
            {intl.formatMessage({
              id: product.name,
            })}
          </h2>
          <p className={styles.description}>
            {intl.formatMessage({ id: product.description })}
          </p>
        </div>
        <div className={styles.detailsFakeLink}>
          <FormattedMessage id="general.details_link" />
        </div>
      </Link>
    </div>
  );
};
